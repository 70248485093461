.st0 {
    fill: #59237f;
}
.st1 {
    fill: #76747d;
}
.st2 {
    opacity: 0.49;
    fill: #76747d;
}

.st0_inverse {
    fill: #fff;
}

.st1_inverse {
    opacity: 0.9;
    fill: #fff;
}

.st2_inverse {
    opacity: 0.8;
    fill: #fff;
}
