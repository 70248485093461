.cls1 {
    font-size:244.76px;
}

.cls1,.cls3,.cls5{
    fill:#fff;
}

.cls1,.cls5 {
    font-family:Poppins-Bold, Poppins;
    font-weight:700;
}

.cls2{
    font-family:Poppins-Light, Poppins;
    font-weight:300;
    letter-spacing:-0.02em;
}

.cls4 {
    fill:#bba3cb;
}

.cls5 {
    font-size:101.33px;
}

.cls6 {
    font-size:39.08px;
}